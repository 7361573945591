<template>
  <v-date-picker
    locale="fr"
    :value="value"
    @input="handleChange"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <input
        :class="{'border-danger': hasError}"
        class="form-control"
        :value="inputValue"
        v-on="inputEvents"
      />
    </template>
  </v-date-picker>
</template>

<script>
import inputMixin from '@/modules/forms/components/inputs/inputMixin'

export default {
  mixins: [inputMixin]
}
</script>
